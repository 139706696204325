import React, { useRef, useEffect, useState, useContext } from 'react';
import { Route, Routes, useNavigate, useLocation, Link } from 'react-router-dom';
import { InfinitySpin, Radio, Blocks } from 'react-loader-spinner'
import Countdown, { zeroPad } from 'react-countdown';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';

import Cookies from 'js-cookie';

import Moment from 'react-moment';

import MiContexto from '../MiContexto'

import { resizeNavBar } from './NavBarJs'
import { getRandomNumber } from './Features/functions';

import { useTranslation } from 'react-i18next';

import MyAds from "../MyAds";
import { set } from 'date-fns';



export default function Dashboard(props) {

    const { t } = useTranslation();
    const { mobiles, adsMode, demoMode } = useContext(MiContexto)

    const navigate = useNavigate();



    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        resizeNavBar()
    }, [])


    const mobileQuotaFinish = (mobile) => {
        return ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
                props.loadMobilesFromCookie()
            } else {
                return <span className='font-weight-bold text-danger p-2 rounded h6'>{t('Dashboard.expiresOn')}: {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
            }
        }
    }


    if (props.isLoading)
        return <div className="container text-center " style={{ paddingTop: "250px" }}>
            <Player
                autoplay={true}
                loop={true}
                controls={false}
                src="/images/lottie-loading1.json"
                style={{ height: '120px', width: '120px', padding: '0px' }}
            ></Player>
            <div className=' small' style={{ fontWeight: "600" }}>
                Cargando Móviles...
            </div>
        </div>


    return (
        <div className='container mb-4 ' style={{ height: "100%", minHeight: "100vh" }} onClick={() => resizeNavBar()} >


            {mobiles.length > 0 && <div className='h4 text-uppercase' style={{ paddingTop: "25px" }}>{t('Dashboard.title')}</div>}
            {/* {!mobiles && <InfinitySpin />} */}
            {mobiles.length == 0 &&
                <div className='text-center ' style={{ paddingTop: "80px" }}>

                    <i className='fa fa-warning fa-3x text-secondary' />
                    <div className='mt-1 text-light'>{t('Dashboard.noMoviles')}</div>

                    <div className='mt-3'>
                        <Fade left><button className='btn btn-success blueShadow' onClick={() => navigate('/searchPhone')}>{t('Dashboard.btnRastrear')} <i className='fa fa-search ml-1' /></button></Fade>
                    </div>

                    <Fade>
                        <div className='d-flex justify-content-center mt-2 '>
                            <div className='col-md-6 text-left'>
                                <hr className='bg-secondary' />

                                <div className=' rounded text-secondary' style={{ backgroundColor: "rgba(0, 0, 0,.0)" }}>
                                    <div className='small mt-4  '>
                                        <i className='fa fa-chevron-right  text-success  mr-1 ' /><span className='font-weight-bold'>{t('Dashboard.subTitle1')}</span>
                                        <div className='text-left'>{t('Dashboard.text1')} </div>
                                    </div>
                                    <div className='small mt-4'><i className='fa fa-chevron-right text-success mr-1' /><span className='font-weight-bold'>{t('Dashboard.subtitle2')}</span>
                                        <div className='text-left'>{t('Dashboard.text2')}</div>
                                    </div>
                                    <div className='small mt-4 '><i className='fa fa-chevron-right text-success mr-1' /><span className='font-weight-bold'>{t('Dashboard.subTitle3')}</span>
                                        <div className='text-left'>{t('Dashboard.text3')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                </div>}

            <div className='d-flex justify-content-center'>
                <div style={{ width: "550px" }}>
                    {mobiles.map((mobile, index) => {
                        //console.log(mobile)
                        // MOVIL INFO EN DASHBOARD##############################
                        let fondo = 'fondoTransOscuro'
                        if (mobile.payment) fondo = 'fondoTransVerde'
                        let borde = '1px solid rgba(7, 65, 121, 0.8)'
                        if (mobile.payment) borde = "null"
                        return (

                            <Bounce key={index}>
                                <div key={index} className={` mt-3 mb-0 text-center p-1 small `} onClick={() => props.handleBuy(mobile)}>
                                    <div className={`justify-content-between ${fondo} blackGradient blueShadow`} style={{ borderRadius: '8px', border: borde }} >
                                        <img src={mobile.wppPicture} className="rounded-circle blob green" style={{ width: "65px", height: "65px", borderRadius: '15px', border: `2px solid #28a745` }} alt="Avatar" />
                                        {mobile.wppAbout && <div className='mb-1 font-italic text-secondary mt-0'>{mobile.wppAbout}</div>}
                                        <div className='font-weight-bold text-success pt-0 h4'><i className='fa fa-mobile' /> {mobile.number}</div>

                                        {demoMode && <Countdown date={Number(mobile.expires)} daysInHours={true} renderer={mobileQuotaFinish(mobile)} />}

                                        {mobile.payment ?
                                            <div >
                                                <div className='my-2'><PaymentInf payment={mobile.payment} /></div>
                                                <button onClick={() => props.handleBuy(mobile)} className='btn btn-success btn-block btn-sm' >{t('Dashboard.accederAmovil')}</button>
                                            </div>
                                            :
                                            <div>
                                                <div className='my-2'>
                                                    <MobileInf mobile={mobile} handleBuy={() => props.handleBuy(mobile)} />
                                                </div>
                                                {demoMode ?
                                                    <button className='btn btn-danger btn-block btn-sm' onClick={() => props.handleBuy(mobile)}>{t('Dashboard.getFullAccess')} <i className='fa fa-eye ml-1' /></button>
                                                    :
                                                    <button className='btn btn-success btn-block btn-sm' onClick={() => props.handleBuy(mobile)}>ACCEDER </button>
                                                }
                                            </div>
                                        }


                                    </div>
                                </div>
                            </Bounce>

                        )
                        // ######################################################## 

                    })}
                </div>
            </div>

            {/* {mobiles?.length > 0 && adsMode && <MyAds />} */}
        </div>
    )


}


const getUnreadElements = (aNumber) => {
    var expiresInMinutes = new Date(new Date().getTime() + 4 * 60 * 1000);
    const pushUnreadElements = (aNumber) => {
        let unReadElement = { id: aNumber, unReadWpp: getRandomNumber(0, 5), unReadInsta: getRandomNumber(0, 10), unReadFb: getRandomNumber(0, 3), unReadPic: getRandomNumber(0, 40), unReadMap: getRandomNumber(0, 20) }

        const unreadElementsFromCookie = Cookies.get('unReadElements')
        if (!unreadElementsFromCookie) {
            let unReadElements = [unReadElement]
            Cookies.set('unReadElements', JSON.stringify(unReadElements), { expires: expiresInMinutes });
        } else {
            let unReadElements = JSON.parse(unreadElementsFromCookie)
            unReadElements.push(unReadElement)
            Cookies.set('unReadElements', JSON.stringify(unReadElements), { expires: expiresInMinutes });
        }
    }

    const getUnreadElementsFromCookie = (aNumber) => {
        const unreadElementsFromCookie = Cookies.get('unReadElements')
        if (!unreadElementsFromCookie) return null
        let unReadElements = JSON.parse(unreadElementsFromCookie)
        let unReadElement = unReadElements.find((element) => element.id == aNumber)
        if (!unReadElement) return null
        return unReadElement
    }

    let unReadElement = getUnreadElementsFromCookie(aNumber)
    if (!unReadElement) {
        pushUnreadElements(aNumber)
        unReadElement = getUnreadElementsFromCookie(aNumber)
    }
    return unReadElement

}


function PaymentInf(props) {
    const { t } = useTranslation();
    const { adsMode } = useContext(MiContexto)

    const { payment } = props
    if (!payment) return null

    return (
        <div>
            <div className='text-success text-uppercase font-weight-bold'>{payment.duration} {t('Dashboard.subscriptionTime')} <i className='fa fa-check' /></div>
            <div className='text-secondary'>{t('Dashboard.subscriptionInit')}: <Moment date={payment.date} format="LLL" /></div>
            <div className='text-secondary'>{t('Dashboard.subscriptionEnd')} <Moment date={payment.date} add={{ days: payment.duration }} format="LLL" /></div>
            {/* {adsMode && <MyAds adStyle="inFeed" /> } */}
        </div>
    )

}


function MobileInf(props) {

    const { mobiles, adsMode } = useContext(MiContexto)

    const { mobile } = props
    if (!mobile) return null

    const hardware = mobile.hardware
    let imei = hardware.split("|")[0]
    let serial = hardware.split("|")[1]
    let model = hardware.split("|")[2]
    let os = hardware.split("|")[3]

    const location = mobile.location
    const country = location.split("|")[0]
    const flag = location.split("|")[1]
    const city = location.split("|")[2]
    const code = location.split("|")[3]
    const cp = mobile.location.split("|")[4]
    const lat = mobile.location.split("|")[5]
    const lon = mobile.location.split("|")[6]

    const network = mobile.network
    const ispTitle = network.split("|")[0]
    const ispDesc = network.split("|")[1]
    const ip = network.split("|")[2]

    const dateCreated = mobile.dateCreated


    const unReadElement = getUnreadElements(mobile.number)
    //console.log(unReadElement)


    return (
        <div>
            <div className='d-flex justify-content-center small'>
                <div className='div mx-3 text-left'>

                    <div className='text-uppercase'><img src={flag} alt="flag" className='img-fluid' style={{ height: "25px" }} /> {country}</div>
                    <div>{city} {code}</div>

                    <div className='d-flex text-light mt-1' >
                        <div className=''>Lat:  {lat?.toString().substring(0, 7) + "***"}</div>
                        <div className=''>Long: {lon?.toString().substring(0, 7) + "***"}</div>
                    </div>


                </div>
                <div className='div text-left'>
                    <div className=''>IMEI: <span className='font-weight-bold'>{imei}</span></div>
                    <div className=''>SERIAL: <span className='font-weight-bold'>{serial}</span></div>
                    <div className=''>OS: <span className='font-weight-bold'>{os}</span></div>
                    <div className=''>MODELO:<span className='font-weight-bold'> {model}</span></div>
                </div>
            </div>

            <hr className='bg-secondary mx-3 my-1' />
            <div className='d-flex justify-content-center fade-in-div ' onClick={() => props.handleBuy(mobile)}>
                <span className='badge ' style={{ width: "50px" }} ><i className='fa fa-whatsapp ' style={{ fontSize: "22px" }} />{unReadElement.unReadWpp > 0 && <span className='badge badge-danger badge-sm '>{unReadElement.unReadWpp}</span>}</span>
                {/* <span className='badge badge-dark mx-3' style={{ width: "50px" }} ><i className='fa fa-instagram ' style={{ fontSize: "22px" }} />{unReadElement.unReadInsta > 0 && <span className='badge badge-danger badge-sm '>{unReadElement.unReadInsta}</span>}</span> */}
                {/* <span className='badge badge-dark' style={{ width: "50px" }} ><i className='fa fa-facebook ' style={{ fontSize: "22px" }} />{unReadElement.unReadFb > 0 && <span className='badge badge-danger badge-sm '>{unReadElement.unReadFb}</span>}</span> */}
                <span className='badge  mx-3' style={{ width: "50px" }} ><i className='fa fa-picture-o ' style={{ fontSize: "22px" }} />{unReadElement.unReadPic > 0 && <span className='badge badge-danger badge-sm '>{unReadElement.unReadPic}</span>}</span>
                <span className='badge ' style={{ width: "50px" }} ><i className='fa fa-map-marker ' style={{ fontSize: "22px" }} />{unReadElement.unReadMap > 0 && <span className='badge badge-danger badge-sm '>{unReadElement.unReadMap}</span>}</span>
            </div>
            <hr className='bg-secondary mx-3 my-1' />

            {/* {mobiles?.length > 0 && adsMode && <MyAds adStyle="inFeed" />} */}

            <div className=''>
                <div className='text-success text-uppercase font-weight-bold'>{ispTitle}</div>
                <div className='text-secondary'>{ispDesc}</div>
                <div className='text-secondary'>IP: {ip}</div>
            </div>

            <div className='d-flex justify-content-center text-secondary' style={{ lineHeight: "2px" }}>
                <div className='  small mt-3' ><i className='fa fa-' /> Last Sync: <Moment date={dateCreated} format="LLL" /></div>
                <Radio
                    visible={true}
                    height="30"
                    width="30"
                    ariaLabel="radio-loading"
                    wrapperStyle={{}}
                    wrapperClass="radio-wrapper"
                    colors={["#28a745", "#6c757d", "#6c757d"]}
                />

            </div>
        </div>
    )
}

function Network(props) {
    const network = props.network
    if (!network) return null

    const ispTitle = network.split("|")[0]
    const ispDesc = network.split("|")[1]


    return (
        <div>
            <div className='text-primary text-uppercase'>{ispTitle}</div>
            <div className='text-secondary'>{ispDesc}</div>
        </div>
    )
}

