import React, { useState, useEffect, useContext } from 'react'

import MetisMenu from 'react-metismenu';
import './NavBar.css'
import { resize, openNavBar, closeNavBar, resizeNavBar } from './NavBarJs'

import { useTranslation } from 'react-i18next';
import MiContexto from '../MiContexto'


export default function NavBar(props) {

    const { t } = useTranslation();

    //const { user } = props
    const [menuContent, setMenuConten] = useState({ items: [] })
    const mobiles = useContext(MiContexto).mobiles


    useEffect(() => {

        window.addEventListener("resize", resize.bind(resizeNavBar()));

        setMenuConten({
            items: [

                {
                    icon: 'search  iconStyle1',
                    label: t('NavBar.rastrear'),
                    to: '#searchPhone',
                },
                {
                    icon: 'mobile  iconStyle1',
                    label: t('NavBar.movilesRastreados') + " " + (mobiles.length > 0 ? "( " + mobiles.length + " )" : ""),
                    to: '#dashboard',
                },

                // {
                //     icon: 'line-chart fa-1x ',
                //     label: "Dispositivos",
                //     to: '#sales',
                // },
                // {
                //     icon: 'usd fa-1x  ',
                //     label:"Invoices",
                //     to: '#withdraw',
                // },


                {
                    label: '_____________________', // <-- this is a divider
                },

                {
                    icon: 'question-circle fa-1x  ',
                    label: t('NavBar.comoUsar'),
                    to: '#howtouse',
                },

                {
                    icon: 'wrench fa-1x',
                    label: <span className=''>{t('NavBar.asistencia')}</span>,
                    to: '#support',
                },
                {
                    icon: 'podcast ',
                    label: <span className=''>{t('NavBar.serverStatus')}</span>,
                    to: '#status',
                },
                // {
                //     icon: 'sign-out fa-1x text-danger',
                //     label: <span onClick={props.handleLogout} className='text-danger small'>Salir</span>,
                // },
            ]
        })

    }, [])
    //---------------------


    return (

        <React.Fragment>
            {/* ICON OPEN SIDE BAR */}

            <span className="pl-2 pt-1" onClick={() => openNavBar()}> <span className="h2 ">☰</span> </span>

            <div id="mySidebar" className="sidebar " style={{overflowY:'hidden'}}>
                <div className='blueShadow' style={{ backgroundColor: "rgba(0, 0, 0, .9)", paddingTop: "10px", paddingBottom: "10px" , height:"100vh"}}>

                    <a href="javascript:void(0)" className="closebtn text-white  " onClick={() => closeNavBar()}>×</a>

                    <div className="text-center my-2 mx-2">

                        <div onClick={() => closeNavBar()}>
                            <div className='mt-3'><img src={process.env.PUBLIC_URL + "/images/logo.svg"} className="img-responsive" width="75px" /></div>
                            <div><img src={process.env.PUBLIC_URL + "/images/logotext.png"} className="img-responsive" height="15px" /></div>
                        </div>

                        <hr  className='bg-secondary'/>

                        {/* <div><span className='colorVerdeDolar small'>{reseller.info.email}</span></div> */}

                        <div className='my-3 '>
                            {/* <div className='h6 font-weight-bold mt-2 ' style={{lineHeight:"8px"}} >{reseller.info.referCode}</div>
                        <div className='small ' style={{lineHeight:"8px"}} ><small>{reseller.info.referLink}</small></div> */}
                        </div>

                    </div>

                    {/* NAV BAR ----------- */}
                    <div className='text-left'>
                        <MetisMenu iconNameStateVisible="minus" iconNameStateHidden="plus" content={menuContent.items} activeLinkFromLocation />
                    </div>
                    {/* ------------------ */}

                </div>
            </div>
        </React.Fragment>


    )




}




